<template>
  <div>
    <b-tabs v-model="tabIndex" @activate-tab="changed" content-class="mt-3">
      <b-tab title="Campaigns" active>
        <h2>campaigns view</h2>
        <kt-datatable
          :table-data="rows.campaigns"
          :table-header="datagrid.columns"
          v-on:row-click="onRowSelected"
        >
          <template v-slot:cell-selected="{ row: data }">
            <input type="checkbox" v-model="data.selected" :checked="isSelected(data)" />
          </template>
        </kt-datatable>
        {{state.selectedObjectIds}}
      </b-tab>
      <b-tab title="Adsets">
        <h2>adsets view</h2>
        <kt-datatable
          :table-data="rows.adsets"
          :table-header="datagrid.columns"
          v-on:row-click="onRowSelected"
        >
          <template v-slot:cell-selected="{ row: data }">
            <input type="checkbox" v-model="data.selected" :checked="isSelected(data)" />
          </template>
        </kt-datatable>
      </b-tab>
      <b-tab title="Ads">
        <h2>ads view</h2>
        <kt-datatable
          :table-data="rows.ads"
          :table-header="datagrid.columns"
          v-on:row-click="onRowSelected"
        >
          <template v-slot:cell-selected="{ row: data }">
            <input type="checkbox" v-model="data.selected" :checked="isSelected(data)" />
          </template>
        </kt-datatable>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";

type Selection = Array<any>;

export default defineComponent({
  name: "campaigns",
  components: {
    KtDatatable
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Campaigns");
    });
  },
  created() {
    this.updateStateFromFilters()
  },
  data() {
    return {
      tabIndex: 0,
      tabMap: ['campaigns', 'adsets', 'ads'],
      rows: {
        campaigns: [
          {
            "campaign_id": 91,
            "image": "http://dummyimage.com/189x100.png/dddddd/000000",
            "name": "Dusky rattlesnake",
            "spend": 112.77,
            "roas": 1.53,
            "cpa": 13.16,
            "orders": 97.15
          },
          {
            "campaign_id": 92,
            "image": "http://dummyimage.com/213x100.png/5fa2dd/ffffff",
            "name": "Red-breasted cockatoo",
            "spend": 525.31,
            "roas": 1.7,
            "cpa": 14.73,
            "orders": 0.96
          }
        ],
        adsets: [
          {
            "adset_id": 51,
            "campaign_id": 91,
            "image": "http://dummyimage.com/189x100.png/dddddd/000000",
            "name": "Dusky rattlesnake",
            "spend": 112.77,
            "roas": 1.53,
            "cpa": 13.16,
            "orders": 97.15
          },
          {
            "adset_id": 52,
            "campaign_id": 91,
            "image": "http://dummyimage.com/213x100.png/5fa2dd/ffffff",
            "name": "Red-breasted cockatoo",
            "spend": 525.31,
            "roas": 1.7,
            "cpa": 14.73,
            "orders": 0.96
          },
          {
            "adset_id": 53,
            "campaign_id": 92,
            "image": "http://dummyimage.com/175x100.png/dddddd/000000",
            "name": "Blue and yellow macaw",
            "spend": 948.96,
            "roas": 2.07,
            "cpa": 43.99,
            "orders": 8.26
          },
          {
            "adset_id": 54,
            "campaign_id": 92,
            "image": "http://dummyimage.com/202x100.png/dddddd/000000",
            "name": "Yellow-headed caracara",
            "spend": 645.27,
            "roas": 6.32,
            "cpa": 8.43,
            "orders": 99.74
          }
        ],
        ads: [
          {
            "ad_id": 11,
            "adset_id": 51,
            "campaign_id": 91,
            "image": "http://dummyimage.com/189x100.png/dddddd/000000",
            "name": "Dusky rattlesnake",
            "spend": 112.77,
            "roas": 1.53,
            "cpa": 13.16,
            "orders": 97.15
          },
          {
            "ad_id": 12,
            "adset_id": 51,
            "campaign_id": 91,
            "image": "http://dummyimage.com/213x100.png/5fa2dd/ffffff",
            "name": "Red-breasted cockatoo",
            "spend": 525.31,
            "roas": 1.7,
            "cpa": 14.73,
            "orders": 0.96
          },
          {
            "ad_id": 13,
            "adset_id": 51,
            "campaign_id": 91,
            "image": "http://dummyimage.com/175x100.png/dddddd/000000",
            "name": "Blue and yellow macaw",
            "spend": 948.96,
            "roas": 2.07,
            "cpa": 43.99,
            "orders": 8.26
          },
          {
            "ad_id": 14,
            "adset_id": 52,
            "campaign_id": 91,
            "image": "http://dummyimage.com/202x100.png/dddddd/000000",
            "name": "Yellow-headed caracara",
            "spend": 645.27,
            "roas": 6.32,
            "cpa": 8.43,
            "orders": 99.74
          },
          {
            "ad_id": 15,
            "adset_id": 52,
            "campaign_id": 91,
            "image": "http://dummyimage.com/188x100.png/dddddd/000000",
            "name": "Squirrel, eastern fox",
            "spend": 503.94,
            "roas": 7.85,
            "cpa": 51.11,
            "orders": 34.73
          },
          {
            "ad_id": 16,
            "adset_id": 52,
            "campaign_id": 91,
            "image": "http://dummyimage.com/192x100.png/dddddd/000000",
            "name": "Indian mynah",
            "spend": 792.32,
            "roas": 4.03,
            "cpa": 68.33,
            "orders": 47.6
          },
          {
            "ad_id": 17,
            "adset_id": 53,
            "campaign_id": 92,
            "image": "http://dummyimage.com/211x100.png/5fa2dd/ffffff",
            "name": "Vicuna",
            "spend": 936.55,
            "roas": 4.03,
            "cpa": 45.84,
            "orders": 48.65
          },
          {
            "ad_id": 18,
            "adset_id": 53,
            "campaign_id": 92,
            "image": "http://dummyimage.com/138x100.png/ff4444/ffffff",
            "name": "Bushbaby, large-eared",
            "spend": 358.42,
            "roas": 7.64,
            "cpa": 95.76,
            "orders": 81.67
          },
          {
            "ad_id": 19,
            "adset_id": 53,
            "campaign_id": 92,
            "image": "http://dummyimage.com/196x100.png/dddddd/000000",
            "name": "Silver-backed jackal",
            "spend": 55.26,
            "roas": 7.5,
            "cpa": 36.2,
            "orders": 76.09
          },
          {
            "ad_id": 20,
            "adset_id": 54,
            "campaign_id": 92,
            "image": "http://dummyimage.com/105x100.png/5fa2dd/ffffff",
            "name": "Coke's hartebeest",
            "spend": 379.8,
            "roas": 6.88,
            "cpa": 26.44,
            "orders": 7.49
          },
          {
            "ad_id": 21,
            "adset_id": 54,
            "campaign_id": 92,
            "image": "http://dummyimage.com/141x100.png/dddddd/000000",
            "name": "Kite, black",
            "spend": 552.62,
            "roas": 6.03,
            "cpa": 39.39,
            "orders": 61.35
          },
          {
            "ad_id": 22,
            "adset_id": 54,
            "campaign_id": 92,
            "image": "http://dummyimage.com/197x100.png/ff4444/ffffff",
            "name": "Brown hyena",
            "spend": 461.75,
            "roas": 9.32,
            "cpa": 36.84,
            "orders": 17.9
          }
        ]
      },
      datagrid: {
        columns: [
          {
            name: 'selected',
            columnKey: 'selected',
            key: 'selected'
          },
          {
            name: 'campaign_id',
            columnKey: 'campaign_id',
            key: 'campaign_id'
          },
          {
            name: 'image',
            columnKey: 'image',
            key: 'image'
          },
          {
            name: 'spend',
            columnKey: 'spend',
            key: 'spend'
          },
          {
            name: 'roas',
            columnKey: 'roas',
            key: 'roas'
          },
          {
            name: 'CPA',
            columnKey: 'CPA',
            key: 'cpa'
          },
          {
            name: 'orders',
            columnKey: 'orders',
            key: 'orders'
          },
        ]
      },
      state: {
        selectedObjectType: 'campaigns',
        selectedObjectIds: [] as string[]
      }
    }
  },
  methods: {

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
    isSelected(row) {
      return this.findRowIndex(row) > -1
    },
    findRowIndex(row) {
      return this.state.selectedObjectIds.findIndex(id => id == row.campaign_id)
    },
    onRowSelected(item) {
      let foundIdx = this.findRowIndex(item)
      if (foundIdx > -1) {
        this.state.selectedObjectIds.splice(foundIdx, 1);
      } else {
        this.state.selectedObjectIds.push(item.campaign_id)
      }
      this.updateFiltersFromState()
    },
    changed(tabIndex) {
      let objectType = this.tabMap[tabIndex]
console.log('tab changed: ', objectType)
      this.state.selectedObjectType = objectType
      // this.$router.push({name: 'campaigns', params: {objectType: objectType}})
      this.updateFiltersFromState()
    },
    updateFiltersFromState() {
      this.$router.replace({
        name: 'campaigns',
        params: {objectType: this.state.selectedObjectType},
        query: {selected_ids: this.state.selectedObjectIds.join(',')}
      })
    },
    updateStateFromFilters() {
      let selectedIds = this.$route.query.selected_ids as string
      if (selectedIds) {
        this.state.selectedObjectIds = selectedIds.split(',').filter(id => Number.isInteger(parseInt(id)))
      }
      this.state.selectedObjectType = this.$route.params?.objectType as string
    }
  }
});

// todo: extract this
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $refs: {
      [key: string]: HTMLElement | any,
    },
    // ... more stuff
  }
}
</script>
